import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 10px 2%;

    .nav_recover__title{
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        h2{
            font-size: 40px;
            margin: 8px 0;
            letter-spacing: 2px;
            color: #333;
            font-weight: lighter;
            text-transform: uppercase;
        }

        a{
            text-decoration: none;
        }
    }
`;


export const Content = styled.div`
    padding: 100px 0;
    display: flex;
    justify-content: center;
    .form{
        width: 500px;

        .msg_recover{
            width: 100%;
            background-color: rgb(250,250,250);
            padding: 10px 30px;
            border: 1px solid #888;
            margin: 15px 0;
            
            p{
                font-size: 13px;
                color: #444;
                text-align: center;
            }
        }

        form{
            p{
                color: #68C9A9;
                font-size: 11px;
                text-transform: uppercase;
                padding-bottom: 4px;
            }

            input{
                width: 100%;
                padding: 12px;
                border: 1px solid #68C9A9;
            }

            .submit_form{
                display: flex;
                justify-content: flex-end;
            }
        }

    }

    @media screen and (max-width: 768px){
        button{
            width: 100%;
        }
    }
`;