import React, { createContext, useEffect, useState } from "react";
import { Allprodutos } from '../lib/datocms/produtos';  // Função para buscar os produtos do DatoCMS

const productContext = createContext();

const ProductProvider = ({ children }) => {
    const [produtos, setProdutos] = useState([]);
    const [busca, setBusca] = useState([]); // Estado para armazenar os resultados da busca

    const [cart, setCart] = useState(() => {
        try {
            const productsOnLocalStorage = localStorage.getItem('cart');
            return productsOnLocalStorage ? JSON.parse(productsOnLocalStorage) : [];
        } catch (error) {
            return [];
        }
    });

    // LISTAR TODOS OS PRODUTOS DO DatoCMS
    useEffect(() => {
        (async () => {
            const response = await Allprodutos();  // Obtenha os produtos do DatoCMS
            setProdutos(response);
        })();
    }, []);


    // SALVAR O CARRINHO NO LOCALSTORAGE
    useEffect(() => {        
        localStorage.setItem('cart', JSON.stringify(cart));  
    }, [cart]);

    // CATEGORIAS ÚNICAS
    const CategoriaUnica = new Map();
    produtos.forEach((item) => {
        if (!CategoriaUnica.has(item.categoria)) {
            CategoriaUnica.set(item.categoria, item);
        }
    });

    // ADICIONAR PRODUTO NO CARRINHO
    const insertProductOnCart = (product) => {
        const { id, tamanho, nome } = product;

        const productNameWithSize = `${nome} - Tamanho: ${tamanho}`;
        const inCart = cart.find(
            (productInCart) => productInCart.id === id && productInCart.tamanho === tamanho
        );

        if (inCart) {
            setCart(
                cart.map((productInCart) =>
                    productInCart.id === id && productInCart.tamanho === tamanho
                        ? { ...productInCart, qtdCart: productInCart.qtdCart + 1 }
                        : productInCart
                )
            );
        } else {
            setCart([...cart, { ...product, nome: productNameWithSize, qtdCart: 1 }]);
        }
    };

    // DECREMENTAR PRODUTOS DO CARRINHO
    const decrementProductOnCart = (product) => {
        const { id, tamanho } = product;
    
        setCart(
            cart.map((productInCart) => {
                if (productInCart.id === id && productInCart.tamanho === tamanho) {
                    if (productInCart.qtdCart === 1) {
                        return null;
                    } else {
                        return { ...productInCart, qtdCart: productInCart.qtdCart - 1 };
                    }
                }
                return productInCart;
            }).filter(productInCart => productInCart !== null)
        );
    };

    // DELETAR PRODUTO DO CARRINHO
    const deleteProductOnCart = (product) => {
        const { id, tamanho } = product;
        setCart(cart.filter((productInCart) => !(productInCart.id === id && productInCart.tamanho === tamanho)));
    };

    // FUNÇÃO PARA BUSCAR PRODUTOS
    const buscarProdutos = async (searchTerm) => {
        let allProducts = [];
        let currentSkip = 0;
        const limit = 100;
        let shouldLoadMore = true;
    
        try {
            while (shouldLoadMore) {
                // Carrega produtos em lotes de 100
                const produtosBatch = await Allprodutos(limit, currentSkip);
                allProducts = [...allProducts, ...produtosBatch];
    
                // Verifica se o lote trouxe menos produtos do que o limite, indicando o fim da lista
                if (produtosBatch.length < limit) {
                    shouldLoadMore = false;
                } else {
                    currentSkip += limit; // Incrementa o skip para buscar o próximo lote
                }
            }
    
            // Filtra os produtos pelo termo de busca
            const produtosFiltrados = allProducts.filter(produto =>
                produto.nome.toLowerCase().includes(searchTerm.toLowerCase())
            );
    
            setBusca(produtosFiltrados); // Atualiza o estado de busca com os produtos filtrados
            return produtosFiltrados; // Retorna os produtos filtrados
    
        } catch (error) {
            console.error("Erro ao buscar produtos:", error);
            setBusca([]); // Limpa os resultados de busca em caso de erro
            return []; // Retorna um array vazio em caso de erro
        }
    };
    
    

    return (
        <productContext.Provider value={{
            produtos,
            busca,
            setBusca, // Disponibiliza setBusca para ser usado externamente
            buscarProdutos, // Função para buscar produtos
            CategoriaUnica,
            cart,
            insertProductOnCart,
            decrementProductOnCart,
            deleteProductOnCart,
        }}>
            {children}
        </productContext.Provider>
    );
};

export { productContext, ProductProvider };
