import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    padding: 0 2%;


    .categorias{
        padding: 10px 0;

        h3{
            text-align: center;
            font-size: 23px;
            color: #333;
            padding: 5px 0;
        }

        .btns_categorias{


            ul{
                list-style-type: none;
                text-align: center;
                li{
                display: inline-block;
                margin: 10px 5px;
                a{
                    display: block;
                    padding: 6px 8px;
                    text-decoration: none;
                    background-color: #00bfa5;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 5px;
                    transition: all 0.2s;
                }

                a:hover{
                    transform: scale(1.03);
                    box-shadow: 3px 2px 3px 1px rgba(0,0,0,0.32);
                }
            }

            }
            
        }
    }

    .produtos_destaque{
        
        .produtos_destaque__titulo{
            width: 100%;
            border-bottom: 1px solid rgba(44,62,80,0.05);
            text-align: center;
            padding: 40px 0 10px 0;

            h2{
                font-weight: lighter;
                font-size: 22px;
            }
        }


        .produtos_wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 1700px;
            margin: 0 auto;


            .item_sigle{
                border: 1px solid rgba(44,62,80,0.2);
                border-radius: 10px;
                margin: 20px 10px;
                transition: all .2s;
                width: 250px;
                height: 374px;
                display: flex;
                flex-direction: column;
                max-height: 374px;

                a{
                    text-decoration: none;
                    color: #333;
                }

                .item_img{
                    border-radius: 10px;
                    width: 100%;
                    height: 70%;
                    max-height: 300px;
                    position: relative;

                    img{
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .item_cart_btn{
                        padding: 8px 12px;
                        border: 2px solid #2c3e50;
                        background-color: #2c3e50;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        transition: all 0.2s;
                        z-index: 2;
                        cursor: pointer;

                        svg{
                            color: #fff;
                            font-size: 19px;
                        }

                        :hover{
                        background-color: #fff;

                        svg{
                            color: #2c3e50;
                        }
                    }
                    }

                }


                .item_descricao{
                    text-align: center;
                    padding: 5px;

                    h2{
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: lighter;
                    }

                    .priceNoDescont{
                        position: relative;

                        .barraDecont{
                            width: 45px;
                            height: 1px;
                            background-color: red;
                            position: absolute;
                            left: 45%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            transform: rotate(-15deg );
                        }
                    }
                    

                    p{
                        margin: 5px 0;
                        font-size: 13px;
                    }
                    
                }
            }

            .item_sigle:hover{
                transform: scale(1.05);
                box-shadow: 3px 2px 3px 1px rgba(0,0,0,0.22);
            }

            .ver_produtos{
                width: 100%;
                display: flex;
                justify-content: center;
            }

            @media screen and (max-width: 768px){


                .item_sigle{
                    border: 1px solid rgba(44,62,80,0.2);
                    border-radius: 10px;
                    margin: 20px 10px;
                    transition: all .2s;
                    width: 190px;
                    height: 374px;
                    display: flex;
                    flex-direction: column;
                    max-height: 300px;
                }
            }
        }
    }
    
`;