import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 10px 0;
`;

export const Content = styled.div`

    .nav_politic__title{
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        h2{
            font-size: 40px;
            margin: 8px 0;
            letter-spacing: 2px;
            color: #333;
        }

        a{
            text-decoration: none;
        }
    }

    .politic_container__text{

        display: flex;
        justify-content: center;
        .politic_content__text{
        padding: 40px;
        max-width: 1280px;

        p{
            padding: 10px 0;
            color: rgba(44, 62, 80, .6);
        }
    }
    }

`;