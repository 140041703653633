import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    .nav_products_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{
            
            font-size: 35px;
            color: #333;
            padding: 5px 0;
            letter-spacing: 3px;
            
        }

        a{
            text-decoration: none;
        }
        
    }
`;

export const Content = styled.div`
    padding: 15px 2%;

    
    
    .ordenar_produto{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
       
        .select_ordem{

        label p{
            font-size: 13px;
            padding-bottom: 3px;
        }

        select{
            background-color: #fff;
            padding: 8px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #ccc;
        }

        select:focus{
            border: 1px solid #00bfa5;
        }

    }
    

    .filter_mobile{
        width: 200px;
        margin: 16px 0 0 10px;
        padding: 7px;
        border: 2px solid #ccc;
        display: flex;
        justify-content: space-between;
        display: none;

        p{
            color: #777;   
        }

        svg{
            color: #777;
        }


        :hover{
            border: 2px solid #000;
        }
    }
        
    }

    @media screen and (max-width: 768px){
        .ordenar_produto{
            justify-content: center;
            
            .select_ordem{
                select{
                    width: 100%;
                    max-width: 200px;
                }
            }

            .filter_mobile{
                display: flex;
                width: 100%;
                max-width: 200px;
            }
        }
    }


    .products_content{
        padding: 30px 0;
        display: flex;
        justify-content: center;
        
       

        .Filter_category{
            background-color: #f8f9f9;
            width: 250px;
            height: 100%;
            min-height: 300px;
            


        .category{            
            height: 258px;  
            padding: 20px;
            overflow: hidden;
            transition: all ease-in-out 0.2s;
            

            h3{
                letter-spacing: 1px;
                color: #333;
                padding-bottom: 10px;
            }
            
            ul{
                list-style-type: none;

                a{
                    display: inline-block;
                    text-decoration: none;
                    color: #444;
                    padding: 3px 0;
                    cursor: pointer;
                }
            }

            
        }

        .show{
            height: auto;
        }

        .div_border{
            width: 90%;
            margin: 15px auto 0 auto;
            height: 1px;
            background-color: #ccc;
        }

        .icon_down{
            text-align: center;
            
           
            svg{
                font-size: 18px;
                background-color: #ccc;
                width: 30px;
                color: #fff;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                cursor: pointer;
            }
        }


        
    }

        .products{
            width: 100%;
            padding: 0;


            .produtos_wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                max-width: 1700px;
                margin: 0 auto;


            .item_sigle{
                border: 1px solid rgba(44,62,80,0.2);
                border-radius: 10px;
                margin: 20px 10px;
                transition: all .2s;
                width: 250px;
                height: 374px;
                display: flex;
                flex-direction: column;
                max-height: 374px;

                a{
                    text-decoration: none;
                    color: #333;
                }

                .item_img{
                    border-radius: 10px;
                    width: 100%;
                    height: 70%;
                    max-height: 300px;
                    position: relative;

                    img{
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .item_cart_btn{
                        padding: 8px 12px;
                        border: 2px solid #2c3e50;
                        background-color: #2c3e50;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        transition: all 0.2s;
                        z-index: 2;
                        cursor: pointer;

                        svg{
                            color: #fff;
                            font-size: 19px;
                        }
                    }

                    .item_cart_btn:hover{
                        background-color: #fff;

                        svg{
                            color: #2c3e50;
                        }
                    }
                }

                .item_descricao{
                    text-align: center;
                    padding: 5px;

                    h2{
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: lighter;
                    }

                    .priceNoDescont{
                        position: relative;

                        .barraDecont{
                            width: 45px;
                            height: 1px;
                            background-color: red;
                            position: absolute;
                            left: 45%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            transform: rotate(-15deg );
                        }
                    }
                    

                    p{
                        margin: 5px 0;
                        font-size: 13px;
                    }
                }
            }

            .item_sigle:hover{
                transform: scale(1.05);
                box-shadow: 3px 2px 3px 1px rgba(0,0,0,0.22);
            }

            .ver_produtos{
                width: 100%;
                display: flex;
                justify-content: center;
            }

        }
        
    }
    @media screen and (max-width: 768px){

        .produtos_wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;

            .item_sigle{
                max-width: 100%;
                height: 100%;
                max-height: 700px;
                display: block;
            }
        }
    }



    @media screen and (max-width: 768px){
        .Filter_category{
            display: none;
        }       
    }
        

}
    

`;


export const FilterModal = styled.div`


    .filter_mobile__actived{
        width: 100%;
        height: 100%;
        top: 0;
        right: -100%;
        position: fixed;
        background-color: #fff;
        padding: 15px 10px;
        z-index: 3;
        transition: all ease-in-out 0.45s;
        overflow: auto;
    }

    .close_modal{
        display: flex;
        align-items: center;
        background-color: rgb(250,250,250);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px;

        svg{
            font-size: 25px;
            margin-right: 15px;
        }

        svg:active{
            color: red;
        }
    }

    .modal_category{
        margin-top: 40px;
        height: 280px;
        overflow: hidden;

        ul{
            li{
                padding: 8px 0;
                font-size: 14px;

                a{
                    text-decoration: none;
                    color: #444;
                }
            }
        }
    }

    .show{
            height: auto;
        }

        .div_border{
            width: 90%;
            margin: 15px auto 0 auto;
            height: 1px;
            background-color: #ccc;
        }

        .icon_down{
            text-align: center;
            
           
            svg{
                font-size: 18px;
                background-color: #ccc;
                width: 30px;
                color: #fff;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                cursor: pointer;
            }
        }

        .filter{
            padding: 20px;
            h3{
                letter-spacing: 1px;
                color: #333;
                padding-bottom: 10px;
            }
            
            ul{
                list-style-type: none;

                li{
                    display: block;

                    a{
                        text-decoration: none;
                        color: #444;
                        padding: 3px 0;
                        display: flex;
                    }

                    label{
                        display: flex;
                        align-items: center;
                    }
                    

                    .checkbox{
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        

                        svg{
                            font-size: 25px;
                        }
                    }

                    :hover{
                        .checkbox{  
                            border: 1px solid #333;
                        }              
                    }

                }
                
            }

            

        }

        .showModal{
            right: 0;
        }
`;