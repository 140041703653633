import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    background-color: #a7ffeb;
    margin-top: 95px;

    @media screen and (max-width: 768px){
        margin-top: 0;
    }
`;

export const Content = styled.div`
    padding: 30px 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    


    .footer_form{

        h3{
        font-size: 18px;
        text-align: center;
        padding: 10px 0;
        color: #00bfa5 ;
        text-transform: uppercase;
        font-size: 15px;
    }

    form{
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
    }

        form input{
        padding: 10px;
        border: 0;
        margin: 0 0 0 10px;
    }

    form button{
        border: 0;
        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            font-size: 38px;
            color: #00bfa5;
            cursor: pointer;
        }
    }

    }


    .social_midias{
        text-align: center;

        h3{
            color: #00bfa5;
            padding: 10px 0;
            text-transform: uppercase;
            font-size: 15px;
        }


        .social_links{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .link_sigle a{
            padding: 8px;
            background-color: red;
            display: flex;
            align-items: center;
            margin: 0 5px;
            background-color: #64ffda;
            border-radius: 5px;
            color: #00bfa5;
            transition: all  .2s;


            svg{
                font-size: 30px;
            }
        }

        .link_sigle a:hover{
            transform: scale(1.08);
        }
    }

    .footer_contato{
        padding: 10px;
        width: 400px;

        h3{
            text-align: center;
            color: #00bfa5;
            text-transform: uppercase;
            font-size: 15px;
            padding: 8px 0;
        }

        
        
        .social_contact{
            text-align: center;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #00bfa5;

            ul{
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                margin: 0 auto;
                color: #00bfa5;
            }

            li{
                
                padding: 10px;
                font-size: 13px;
            }


            a{
                text-decoration: none;
                color: #00bfa5;
            }

            a:hover{
                color: #00796b;
            }
            
        }

        
    }

    .footer_navs{
        width: 100%;
        padding-top: 40px;

        

        ul{
            list-style-type: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        li{
            display: inline-block;
        }

        li a{
            text-decoration: none;
            color: #00bfa5;
            display: block;
            margin: 0 20px;
            font-size: 15px;
        }

        li a:hover{
            color: #1de9b6 ;
        }
    }


    @media screen and (max-width: 1060px){
        display: flex;
        justify-content: center;
        

        .footer_form{
            justify-content: center;

            form{
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                input{
                    margin: 10px 0;
                    width: 100%;
                }

                button{
                    border: 0;
                    background-color: transparent;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg{
                        font-size: 38px;
                        color: #00bfa5;
                        cursor: pointer;
                    }
                }
            }
        }

        .social_midias{
            width: 100%;
            justify-content: center;
            text-align: center;
            padding: 20px 0;

            .social_links{
                justify-content: center;
            }

            
        }

        .footer_navs{

            ul{               
                li{
                    a{
                        font-size: 14px;
                    }
                }
            }
            
        }

    }
    @media screen and (max-width: 680px){
        display: flex;
        justify-content: center;
        

        .footer_form{
            justify-content: center;

            form{
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                input{
                    margin: 10px 0;
                    width: 100%;
                }

                button{
                    border: 0;
                    background-color: transparent;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg{
                        font-size: 38px;
                        color: #00bfa5;
                        cursor: pointer;
                    }
                }
            }
        }

        .social_midias{
            width: 100%;
            justify-content: center;
            text-align: center;
            padding: 20px 0;

            .social_links{
                justify-content: center;
            }

            
        }

        .footer_navs{

            ul{
                list-style-type: none;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                
                li{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    a{
                        font-size: 14px;
                    }
                }
            }
            
        }

    }
`;


export const FooterBottom = styled.div`
    width: 100%;
    padding: 10px 0;
    background-color: #00bfa5 ;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
        text-align: center;
        font-size: 14px;
        color: #fff;
        font-weight: lighter;
    }

    svg{
        color: #fff;
        margin: 0 5px;
    }


    
`;