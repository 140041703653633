import React, { useState, useRef, useEffect, useContext } from 'react';
import { Container, Content } from './style';
import { Link, useParams } from 'react-router-dom';
import { FaArrowUp, FaArrowDown, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { BtnPrimry, BtnSecondary } from '../../styles/Buttons';
import { productContext } from '../../contexts/productsContext';
import { Allprodutos } from '../../lib/datocms/produtos';
import { Context as authContext } from '../../contexts/authContext';
import { api } from '../../hooks/useApi';

const SingleProduct = () => {
    const produtoRef = useRef(null);
    const [sigleItem, setSigleItem] = useState({});
    const [arrImgs, setArrImgs] = useState([]);
    const [tamanho, setTamanho] = useState('');
    const [tamanhosDisponiveis, setTamanhosDisponiveis] = useState([]);
    const carousel = useRef(null);
    const mobileCorousel = useRef(null);
    const imgIndex = useRef([]);
    imgIndex.current = [arrImgs];
    const mainImgProduct = useRef(null);
    const { insertProductOnCart } = useContext(productContext);
    const { slug } = useParams();
    const { authenticated, user } = useContext(authContext);
    const [reviews, setReviews] = useState([]);
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const isLoggedIn = Boolean(user);
    const username = user?.nome ? user.nome.split(' ').slice(0, 2).join(' ') : '';

    // Função para buscar avaliações
    const fetchReviews = async () => {
        try {
            const response = await api.get(`/produto/${slug}/reviews`);
            setReviews(response.data); // Atualiza o estado com as avaliações
        } catch (error) {
            console.error('Erro ao buscar avaliações:', error);
        } finally {
            setIsLoading(false); // Indica que o carregamento terminou
        }
    };

    useEffect(() => {
        fetchReviews();

        // Listener para detectar quando o usuário volta à aba
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchReviews();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [slug]); // Dependência: Executar sempre que o slug mudar
    

    useEffect(() => {
        const fetchProduct = async () => {
            const allProdutos = await Allprodutos();
            const product = allProdutos.find(item => item.slug === slug);
            setSigleItem(product);
            setArrImgs(product.outrasImagens);
            setTamanhosDisponiveis(product.tamanhoDoProduto.map(t => t.tamanho));
        };
        fetchProduct();
    }, [slug]);

    useEffect(() => {
        if (produtoRef.current) {
            produtoRef.current.scrollIntoView();
        }
    }, [sigleItem]);

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        if (!isLoggedIn) {
            alert('Você precisa estar logado para enviar uma avaliação.');
            return;
        }
    
        try {
            const response = await api.post(`/produto/${slug}/review`, {
                username,  // Nome do usuário
                comment,   // Comentário da avaliação
                rating,    // Nota dada
            });
    
            // Verifica se o código de status é 200 ou 201 (sucesso)
            if (response.status === 200 || response.status === 201) {
                alert('Avaliação enviada com sucesso!');
                setComment('');
                setRating('');
                fetchReviews(); // Atualizar as avaliações
            } else {
                alert(`Erro ao enviar avaliação. Código de erro: ${response.status}`);
            }
        } catch (error) {
            console.error('Erro ao enviar avaliação:', error);
    
            // Se o erro for relacionado a uma resposta da API, mostramos os detalhes
            if (error.response) {
                alert(`Erro ao enviar avaliação: ${error.response?.data?.msg || error.response?.data || error.message}`);
            } else {
                alert(`Erro ao enviar avaliação: ${error.message}`);
            }
        }
    };
    

    const handleClickUp = () => carousel.current.scrollTop -= carousel.current.offsetHeight;
    const handleClickDown = () => carousel.current.scrollTop += carousel.current.offsetHeight;
    const handleClickLeft = () => mobileCorousel.current.scrollLeft -= mobileCorousel.current.offsetWidth;
    const handleClickRight = () => mobileCorousel.current.scrollLeft += mobileCorousel.current.offsetWidth;

    const { nome, valor, categoria } = sigleItem;
    const stringPrice = valor?.toFixed(2).replace('.', ',');
    const parcela = (valor / 2)?.toFixed(2).replace('.', ',');

    return (
        <Container>
            <div className='nav_item_title'>
                <p ref={produtoRef}><Link to='/'>Início</Link> / <Link to={`/produtos/${categoria}`}>{categoria}</Link> / {nome}</p>
                <h3>{nome}</h3>
            </div>

            <Content>
                <div className='produto'>
                    <div className='carousel_images_desktop'>
                        <div className='arrow_up'><FaArrowUp onClick={handleClickUp} /></div>
                        <div className='img_wrapper' ref={carousel}>
                            {arrImgs.map((img, index) => (
                                <div className='item_img' key={index}>
                                    <img
                                        alt='item_img'
                                        src={img.url}
                                        ref={(el) => imgIndex.current[index] = el}
                                        onClick={() => mainImgProduct.current.src = imgIndex.current[index].src}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='arrow_down'><FaArrowDown onClick={handleClickDown} /></div>
                    </div>

                    <div className='main_image'>
                        <img className='img_principal' alt='img_principal' src={arrImgs[0]?.url} ref={mainImgProduct} />
                        
                        {/* Carousel Mobile */}
                        <div className='carousel_images_mobile'>
                            <div className='arrow_left'><FaArrowLeft onClick={handleClickLeft} /></div>
                            <div className='img_wrapper' ref={mobileCorousel}>
                                {arrImgs.map((img, index) => (
                                    <div className='item_img' key={index}>
                                        <img
                                            alt='item_img'
                                            src={img.url}
                                            ref={(el) => imgIndex.current[index] = el}
                                            onClick={() => mainImgProduct.current.src = imgIndex.current[index].src}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='arrow_right'><FaArrowRight onClick={handleClickRight} /></div>
                        </div>

                        <div className='info_produto'>
                            <h2>R$ {stringPrice}</h2>
                            <p>2x de R${parcela} sem juros</p>

                            <div className="size-selection">
                                <p>Escolha o tamanho:</p>
                                <div className="sizes">
                                    {tamanhosDisponiveis.map((size) => (
                                        <button
                                            key={size}
                                            onClick={() => setTamanho(size)}
                                            className={tamanho === size ? 'selected' : ''}
                                        >
                                            {size}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <BtnPrimry
                                primary
                                pad
                                upper
                                onClick={() => {
                                    if (!tamanho) {
                                        alert("Por favor, selecione um tamanho");
                                        return;
                                    }
                                    insertProductOnCart({ ...sigleItem, tamanho });
                                }}
                            >
                                incluir no carrinho +
                            </BtnPrimry>
                            {sigleItem?.descricao && (
                                <div className='descricao'>
                                    <h3>Descrição: </h3>
                                    <p>{sigleItem?.descricao}</p>
                                </div>
                            )}
                        </div>

                        {authenticated ? (
                            <div className="add-review">
                                <h3>Deixe sua avaliação:</h3>
                                <form onSubmit={handleReviewSubmit}>
                                    <textarea
                                        placeholder="Seu comentário"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        required
                                    ></textarea>
                                    <div className='select_btn'>
                                        <select
                                            value={rating}
                                            onChange={(e) => setRating(e.target.value)}
                                            required
                                        >
                                            <option value="">Selecione uma nota</option>
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <option key={star} value={star}>{star} estrela(s)</option>
                                            ))}
                                        </select>
                                        <BtnSecondary 
                                            secondary
                                            upper 
                                            pad 
                                        >
                                        Avaliar
                                        </BtnSecondary>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div>
                                <p style={{textAlign: 'center', color: '#00bfa5'}}>
                                    Você precisa estar logado para enviar uma avaliação.
                                </p>
                                    <p style={{textAlign: 'center'}}><Link to={'/login'} style={{color: '#00bfa5'}}>Clique aqui para fazer login!</Link></p>
                            </div>
                        )}

                        <div className="reviews">
                            <h3>Avaliações:</h3>
                            {reviews.length > 0 ? (
                                reviews.map((review, index) => (
                                    <div key={index} className="review">
                                        <div className='user_details'>
                                            <div className='user_name'>
                                                <strong className='nome'>{review.username}</strong>
                                            </div> 
                                            <div className='star'>
                                                {Array(review?.rating).fill('⭐').map((star, index) => (
                                                <span key={index}>{star}</span>))}
                                            </div>
                                        </div>
                                        <div className='comment_data'>
                                            <p>{review?.comment}</p>
                                            <small>{new Date(review.createdAt).toLocaleDateString()}</small>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p style={{textAlign: 'center'}}>Seja o primeiro a avaliar este produto!</p>
                            )}
                        </div>
                    </div>
                </div>
            </Content>
        </Container>
    );
};

export default SingleProduct;