import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .nav_perfil_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{
            
            font-size: 35px;
            color: #333;
            padding: 5px 0;
            letter-spacing: 3px;
            font-weight: lighter;
        }

        a{
            text-decoration: none;
        }
        
    }
`;

export const Content = styled.div`

    .perfil__pedidos{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1400px;
        margin: 30px auto;
        flex-wrap: wrap;
    

    .perfil{
        width: 500px;

        svg{
            color: #00bfa5;
        }

        svg:first-child{
            font-size: 25px;
        }

        svg:last-child{
            font-size: 20px;
        }

        .perfil_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #333;
            padding: 20px 0 10px 0;

            .perfil_editar{
                display: flex;
                justify-content: space-between;
                width: 20%;
                align-items: center;

                a{
                    text-decoration: none;
                    color: #333;
                }
            }

        }

        .perfil_dados{
            p{
                padding: 10px 0;
                border-bottom: 1px solid rgba(44,62,80,0.1);
                color: #00bfa5;
            }

            .button_painel{
                padding: 10px 12px;
                margin: 20px 0;
                border: 2px solid #00bfa5;
                border-radius: 6px;
                text-transform: uppercase;
                background-color: #00bfa5;
                color: #fff;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                font-size: 14px;
                font-weight: bold;
                transition: all ease-in-out .25s;
                letter-spacing: 1px;

                :hover{
                    background-color: #fff;
                    color: #444;
                }
            }
        }
    }

    .pedidos{
        text-align: center;
        color: #333;
        width: 50%;

        .pedidos_single{
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            
            p{
                padding: 10px 5px;
            }
        }


        svg{
            color: #00bfa5;
            font-size: 25px;
            display: none;
        }
  
        .pedido_title{
            padding: 20px 0 10px 0;
        }

        .ver_pedidos{
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #00bfa5;
            margin: 10px 0;
            text-transform: uppercase;
            font-weight: bold;
        }
    }


    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0 30px;
        align-items: center;
        justify-content: center;
        
        .perfil{
            svg:last-child{
                display: none;
            }

            .perfil_top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #333;
                padding: 20px 0;
                flex-wrap: wrap;

                .perfil_editar{
                    display: flex;
                    justify-content: space-between;
                    width: 10%;
                    align-items: center;

                    a{
                        text-decoration: none;
                        color: #333;
                    }
            }

        }
        }

        .pedidos{
            svg{
                display: inline;
            }

            .pedidos_single{
                border-bottom: 1px solid #00bfa5;
                padding: 20px 0;
                flex-wrap: wrap;
                h4{
                    font-size: 14px;
                }
                p{
                    font-size: 14px;
                }
            }

        }
    }
}

`;