import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Content } from "./style";
import { productContext } from '../../contexts/productsContext';

const Buscar = () => {
    const { busca, buscarProdutos } = useContext(productContext); // Pegando o estado e a função de busca do contexto

    const location = useLocation(); // Usado para acessar a query string
    const queryParams = new URLSearchParams(location.search); // Extrai os parâmetros da URL
    const searchTerm = queryParams.get("termo"); // Obtém o termo da query string

    // Realiza a busca quando o componente carrega ou quando o termo muda
    useEffect(() => {
        if (searchTerm) {
            buscarProdutos(searchTerm); // Chama a função de busca com o termo da URL
        }
    }, [searchTerm, buscarProdutos]);

    if (!busca || !Array.isArray(busca)) {
        return <h3>Erro ao carregar os resultados da busca.</h3>;
    }

    return (
        <Container>
            <div className='nav_products_title'>
                <h3>Resultado da pesquisa</h3>
            </div>

            <Content>
                <div className="products_content">
                    <div className="products">
                        <div className='produtos_wrapper'>
                            {
                                // Verifica se o array 'busca' está vazio
                                busca.length === 0 ? (
                                    <h3>Nenhum produto encontrado</h3>
                                ) : (
                                    // Mapeia e renderiza os produtos encontrados
                                    busca.map((item, index) => {
                                        const { nome, imagemPrincipal, valor, slug } = item;
                                        const priceMorePercent = (valor * 1.4).toFixed(2).replace('.', ',');
                                        const formatedPrice = valor.toFixed(2).replace('.', ',');

                                        return(
                                            <div className='item_sigle' key={index} slug={item.slug}>                  
                                                <div className='item_img'>
                                                <Link to={`/produto/${slug}`}>
                                                    <img src={`${imagemPrincipal?.url}`} title={nome} alt={nome}/>
                                                </Link>
                                                </div>
                                                <Link to={`/produto/${item.slug}`}>
                                                <div className='item_descricao'>
                                                    <h2>{nome}</h2>
                                                    <div className='priceNoDescont'>
                                                        <div className='barraDecont'></div>
                                                        <p>DE <strong>R${priceMorePercent}</strong></p>
                                                    </div>
                                                    <p>Por apenas</p>
                                                    <strong>R${formatedPrice}</strong>
                                                </div>
                                                </Link>
                                            </div>
                                        );
                                    })
                                )
                            }
                        </div> {/* produtos_wrapper */}
                    </div> {/* products */}
                </div>
            </Content>
        </Container>
    );
}

export default Buscar;
