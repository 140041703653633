import React,{useState} from 'react';
import { Container, Content, FooterBottom } from './style';
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaCopyright, FaFacebookF } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { api } from '../../hooks/useApi';
import { Link } from 'react-router-dom';
const Footer = () =>{

    const [values,setValues] = useState();

    const handleChangeValues = (value) =>{
        setValues((prev) =>({
            ...prev,
            [value.target.name]: value.target.value
        }))
        console.log(values);
    }


    const handleSubmitContat = (e) =>{
        e.preventDefault();
        api.post('/receber-novidades',{
            nome: values.nome,
            email: values.email
        }).then(response =>{
            alert('E-mail registrado com sucesso! você receberá todas as nossas novidades.');
            console.log(response);
        }).catch(error =>{
            alert(error.response.data.msg);
        })
    }

    return(
        <Container>
            <Content>
                <div className='footer_form'>
                    <h3>Receber novidades</h3>
                    <form>
                        <input type='text' name='nome' placeholder='Nome completo' onChange={handleChangeValues} required/>
                        <input type='email' name='email' placeholder='Seu Email' onChange={handleChangeValues} required/>
                        <button><BsFillArrowRightSquareFill onClick={handleSubmitContat}/></button>
                    </form>
                </div>

                <div className='social_midias'>
                    <h3>Redes sociais</h3>
                    <div className='social_links'>
                        <div className='link_sigle'>
                            <a href='https://www.instagram.com/njresportesof/' target='_blank'><AiOutlineInstagram/></a>
                        </div>
                        <div className='link_sigle'>
                        <a href='#' target='_blank'><FaFacebookF/></a>
                        </div>
                        <div className='link_sigle'>
                        <a href='mailto:suporte@njresportes.com'><HiOutlineMail/></a>
                        </div>
                    </div>
                </div>
                <div className='footer_contato'>
                    <h3>Contato</h3>
                    <div className='social_contact'>
                        <ul>
                            {/* <li>(88) 88888-8888</li>
                            <span>|</span> */}
                            <li><a href="mailto:suporte@njresportes.com">suporte@njresportes.com</a></li>
                        </ul>
                    </div>
                </div>
                <div className='footer_navs'>
                    <nav>
                        <ul>
                            <li><a href='/'>INÍCIO</a></li>
                            <li><a href='/produtos'>PRODUTOS</a></li>
                            <li><a href='/contato'>CONTATO</a></li>
                            <li><a href='/politic'>POLÍTICA DA LOJA</a></li>
                        </ul>
                    </nav>
                </div>
            </Content>
            <FooterBottom>
            <FaCopyright/><h3>NJR ESPORTES - Todos os direitos reservados.</h3>
            </FooterBottom>
        </Container>
    );
}


export default Footer;