import React, { useContext } from 'react';
import { Container } from './style';
import { BtnPrimry } from '../../styles/Buttons';
import { Link } from 'react-router-dom';
import { productContext } from '../../contexts/productsContext';

const Home = () => {
  const { produtos, CategoriaUnica } = useContext(productContext);  // Usando o contexto para pegar produtos e categorias

  // Verifique se `produtos` e `CategoriaUnica` estão definidos
  if (!produtos || produtos.length === 0) {
    return <p>Carregando produtos...</p>; // Estado de carregamento para produtos
  }

  if (!CategoriaUnica || CategoriaUnica.size === 0) {
    return <p>Carregando categorias...</p>; // Estado de carregamento para categorias
  }

  return (
    <Container>
      {/* Seção de Categorias */}
      <div className='categorias'>
        <h3>Categorias</h3>
        <div className='btns_categorias'>
          <ul>
            {[...CategoriaUnica.keys()].map((item, index) => (
              <li key={index}>
                <Link to={`/produtos/${item}`} id={item}>{item}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Seção de Produtos em Destaque */}
      <div className='produtos_destaque'>
        <div className='produtos_destaque__titulo'>
          <h2>PRODUTOS EM DESTAQUE</h2>
        </div>

        <div className='produtos_wrapper'>
          {produtos.slice(0, 8).map((item, index) => {
            const { nome, imagemPrincipal, valor, slug } = item;  // Usando a estrutura dos produtos vindos do DatoCMS
            const priceMore = valor * 0.4;
            const priceMorePercent = valor + priceMore;
            const StringPriceMorePercent = priceMorePercent.toFixed(2).replace('.', ',');
            const stringPrice = valor.toFixed(2).replace('.', ',');

            return (
              <div className='item_sigle' key={index} slug={slug}>
                <div className='item_img'>
                  <Link to={`produto/${slug}`}>
                    <img
                      src={imagemPrincipal?.url}
                      title={nome}
                      alt={nome}
                    />
                  </Link>
                </div>
                <Link to={`produto/${slug}`}>
                  <div className='item_descricao'>
                    <h2>{nome}</h2>
                    <div className='priceNoDescont'>
                      <div className='barraDecont'></div>
                      <p>DE <strong>R${StringPriceMorePercent}</strong></p>
                    </div>
                    <p>Por apenas</p>
                    <strong>R${stringPrice}</strong>
                  </div>
                </Link>
              </div>
            );
          })}

          {/* Botão para ver todos os produtos */}
          <div className='ver_produtos'>
            <Link to='/produtos'>
              <BtnPrimry primary pad upper>Ver todos os produtos</BtnPrimry>
            </Link>
          </div>
        </div> {/* produtos_wrapper */}
      </div>
    </Container>
  );
};

export default Home;
