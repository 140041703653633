import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 10px 2%;

    .nav_criar__title{
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        h2{
            font-size: 40px;
            margin: 8px 0;
            letter-spacing: 2px;
            color: #333;
        }

        a{
            text-decoration: none;
        }
    }
`;


export const Content = styled.div`
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
    min-height: 300px;

    .form_cadastro{
        width: 600px;

        .input_style{
            margin-top: 15px;
            position: relative;
            p{
                color: #68C9A9;
                text-transform: uppercase;
                font-size: 11px;
                padding-bottom: 2px;
            }

            .ver_senha{                               
                background-color: #f7f7f7;
                padding:  11.5px;
                text-decoration: none;
                color: #333;
                text-transform: uppercase;
                font-size: 14px;
                position: absolute;
                right: 1px;
                top: 16px;
                cursor: pointer;

                :hover{
                    background-color: #f1f1f1;
                }
            }

            input{
                width: 100%;
                border: 1px solid #68C9A9;
                padding: 12px;

                ::placeholder{
                    color: #999;
                }
            }
        }


        .btn_entrar{
            display: flex;
            justify-content: flex-end;
        }

        .error_message {
            color: red;
            margin-top: 10px;
            text-align: center;
            font-weight: bold;
        }

        .iniciar_sessao{
            text-align: center;
            padding: 15px 0;
            font-size: 14px;

            a{
                text-decoration: none;
                color: #68C9A9;

                :hover{
                    color: #00BFA5;
                    text-decoration: underline;
                }
            }
        }
    }

    @media screen and (max-width: 768px){
        .form_cadastro{
            button{
                width: 100%;
            }
        }
    }
`;