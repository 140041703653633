import styled from 'styled-components';


const config = {
    bgPrimary: ['#2c3e50','#fff'],
    colorPrimary: ['#2c3e50', '#fff'],
    padPrimary: ['12px 30px','8px 12px'],
    txtTransform: ['uppercase','none'],
    fontSize: ['15px','13px'],
    width: ['200px','300px'],

    bgSencondary: ['#68C9A9', '#fff'],
    colorSecondary: ['#68C9A9', '#fff'],
}



export const BtnPrimry = styled.button`
    padding: 8px 12px;
    border: 2px solid #2c3e50;
    background-color: ${({primary}) => primary ? config.bgPrimary[0] : config.bgPrimary[1]};
    color: ${({color}) => color ? config.colorPrimary[0] : config.colorPrimary[1]};
    padding: ${({pad}) => pad ? config.padPrimary[0] : config.padPrimary[1]};
    text-transform: ${({upper}) => upper ? config.txtTransform[0] : config.txtTransform[1]};
    font-size: ${({size}) => size ? config.fontSize[1] : config.fontSize[0]};
    margin: 10px 0;
    transition: all 0.2s;
    cursor: pointer;

    :hover{
        background-color: ${({primary}) => primary ? config.bgPrimary[1] : config.bgPrimary[0]};
        color: ${({color}) => color ? config.colorPrimary[1] : config.colorPrimary[0]};
    }
`;

export const BtnSecondary = styled.button`
    padding: 8px 12px;
    border: 2px solid #68C9A9;
    background-color: ${({secondary}) => secondary ? config.bgSencondary[0] : config.bgSencondary[1]};
    color: ${({color}) => color ? config.colorSecondary[0] : config.colorSecondary[1]};
    padding: ${({pad}) => pad ? config.padPrimary[0] : config.padPrimary[1]};
    text-transform: ${({upper}) => upper ? config.txtTransform[0] : config.txtTransform[1]};
    font-size: ${({size}) => size ? config.fontSize[1] : config.fontSize[0]};
    margin: 10px 0;
    transition: all 0.2s;
    cursor: pointer;
    width: ${({width}) => width ? config.width[0] : config.width[1]};

    :hover{
        background-color: ${({secondary}) => secondary ? config.bgSencondary[1] : config.bgSencondary[0]};
        color: ${({color}) => color ? config.colorSecondary[1] : config.colorSecondary[0]};
    }
`;
