import React, { useContext } from 'react';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BsFillPersonFill, BsBagFill } from 'react-icons/bs';
import { GrFormNext } from "react-icons/gr";
import { Context } from '../../contexts/authContext';

const Perfil = () => {
    const { user } = useContext(Context);

    return (
        <Container>
            <div className='nav_perfil_title'>
                <p><Link to='/'>Início</Link> / Minha Conta</p>
                <h3>Minha Conta</h3>
            </div>

            <Content>
                <div className='perfil__pedidos'>
                    <div className='perfil'>
                        <div className='perfil_top'>
                            <BsFillPersonFill />
                            <h3>Meu cadastro</h3>
                            <div className='perfil_editar'>
                                <Link to='/perfil/info'>Editar</Link>
                                <BsBagFill />
                            </div>
                        </div>

                        <div className='perfil_dados'>
                            <p><b>Nome Completo</b> | {user.nome}</p>
                            <p><b>Email</b> | {user.email}</p>
                            <p><b>Telefone</b> | {user.telefone}</p>
                            {
                                user.role === process.env.REACT_APP_USER_ROLE ?
                                    <Link to="/Perfil/Painel" className='button_painel'>Ver painel</Link>
                                    :
                                    ''
                            }
                        </div>
                    </div>

                    <div className='pedidos'>
    <h3 className='pedido_title'>Meus pedidos</h3>
    <BsBagFill />
    {user && user.pedidos && user.pedidos.length > 0 ? (
        user.pedidos.map((pedido, pedidoIndex) => (
            <div key={pedidoIndex} className="pedido">
                {pedido.produtos.map((produto, produtoIndex) => {
                    const { nome, quantidade, preco } = produto;
                    const stringPrice = preco?.toFixed(2)?.replace('.', ',');
                    const formatedData = new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(pedido.data));

                    return (
                        <div key={produtoIndex} className="pedidos_single">
                            <h4>{nome}</h4>
                            <p>|</p>
                            <p>QTD: {quantidade}</p>
                            <p>|</p>
                            <p>Valor: R$ {stringPrice}</p>
                            <p>|</p>
                            <p>{formatedData}</p>
                        </div>
                    );
                })}
            </div>
        ))
    ) : (
        <p>Faça sua primeira compra!</p>
    )}
</div>

                </div>
            </Content>
        </Container>
    );
}

export default Perfil;
