import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./routes";
import GlobalStyle from "./styles/GlobalStyles";



const App = () => {

  return (
        <>
        <div className="content">         
          <AppRoutes/>
        </div>
          <Footer/>  
        <GlobalStyle/>
        </>
  );
}

export default App;
