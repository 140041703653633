import styled from 'styled-components';


export const Container = styled.div`

    .nav_painel_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{
            
            font-size: 35px;
            color: #333;
            padding: 5px 0;
            letter-spacing: 3px;
            font-weight: lighter;
        }

        a{
            text-decoration: none;
        }
        
    }
`;

export const Content = styled.div`
    width: 90%;
    max-width: 1500px;
    height: fit-content;
    min-height: 276px;
    margin: 40px auto;

    

    .ordenar_produto{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        flex-wrap: wrap;


        .enviar_novidade{
            padding: 10px 20px;
            border: 2px solid #00bfa5;
            cursor: pointer;
            text-transform: uppercase;
            border-radius: 10px;
            background-color: #00bfa5;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.5px;
            transition: all ease-in-out 0.25s;
            display: flex;
            align-items: center;

            svg{
                font-size: 20px;
                margin-right: 5px;
            }

            :hover{
                background-color: #fff;
                color: #444;
            }
        }

    @media screen and (max-width: 768px){        
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .enviar_novidade{
            padding: 10px 27px;
        }
    }
}
    
    
`;


export const EnviarNovidade = styled.div`
    .overlay{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        z-index: 1;
    }

    .modal_wrapper{
        width: 90%;
        max-width: 800px;
        height: 80%;
        max-height: 400px;
        border: 2px solid #444;
        border-radius: 10px;
        padding: 10px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        display: none;

        h3{
            text-align: center;
            text-transform: uppercase;
            color: #444;
        }

        .btn_close{
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;

            svg{
                font-size: 30px;
                color: #fb3f3f;

                :hover{
                    color: red;
                }
            }
        }

        .form_modal{
            width: 100%;
            margin: 10px auto;
            display: flex;
            justify-content: center;

            form{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 100%;

                textarea{
                    width: 100%;
                    max-width: 700px;
                    height: 200px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 10px;
                    outline: none;
                    resize: vertical;
                }

                input{
                    width: 90%;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin: 10px 0;
                }

                button{
                    padding: 10px 40px;
                    border: 2px solid #00bfa5;
                    cursor: pointer;
                    text-transform: uppercase;
                    border-radius: 10px;
                    background-color: #00bfa5;
                    color: #fff;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    margin: 10px 0;
                    transition: all ease-in-out 0.25s;

                    svg{
                        font-size: 20px;
                        margin-right: 5px;
                    }

                    :hover{
                        background-color: #fff;
                        color: #444;
                    }
                }
                
            }
        }     
    }


    .open_modal{
        display: block;
    }
`;