import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom"; 
import { Container, Content } from "./style";
import { BtnPrimry } from "../../styles/Buttons";
import { productContext } from "../../contexts/productsContext";  // Usando o contexto dos produtos

const Categorias = () => {
    const [itemCategory, setItemCategory] = useState([]);
    const { categoria } = useParams();  // Obtém a categoria da URL
    const [initialPage, setInitialPage] = useState(10);
    const [selected, setSelected] = useState('moreSelling');

    const { produtos } = useContext(productContext);  // Pega os produtos do contexto

    useEffect(() => {
        // Filtra os produtos pela categoria selecionada
        const filteredProducts = produtos.filter(product => product.categoria === categoria);
        setItemCategory(filteredProducts);
    }, [categoria, produtos]);

    const handleChange = (e) => {
        const index = e.target.value;
        setSelected(index);

        const sortedItems = [...itemCategory].sort((a, b) => {
            const { nome, valor, views, _firstPublishedAt } = a;
            const { nome: nomeB, valor: valorB, views: viewsB, _firstPublishedAt: dataB } = b;

            switch (index) {
                case 'priceDesc':
                    return valor - valorB;  // Ordem crescente de preço
                case 'priceAsc':
                    return valorB - valor;  // Ordem decrescente de preço
                case 'A-Z':
                    return nome.localeCompare(nomeB);  // Ordem alfabética crescente
                case 'Z-A':
                    return nomeB.localeCompare(nome);  // Ordem alfabética decrescente
                case 'moreSelling':
                    return viewsB - views;  // Mais vendidos primeiro
                case 'newTime':
                    return new Date(dataB) - new Date(_firstPublishedAt);  // Mais novos primeiro
                case 'oldTime':
                    return new Date(_firstPublishedAt) - new Date(dataB);  // Mais antigos primeiro
                default:
                    return 0;
            }
        });

        setItemCategory(sortedItems);
    };

    const handleShowMore = () => {
        setInitialPage(prev => prev + 10);
    };

    return (
        <Container>
            <div className='nav_products_title'>
                <p><Link to='/'>Início</Link> / <Link to='/produtos'>Produtos</Link> / {categoria}</p>
                <h3>{categoria}</h3>
            </div>

            <Content>
                <div className="ordenar_produto">
                    <div className="select_ordem">
                        <label htmlFor="sortSelect">
                            <p>Ordenar por:</p>
                        </label>
                        <select id="sortSelect" value={selected} onChange={handleChange}>
                            <option value='priceDesc'>Preço: Menor ao Maior</option>
                            <option value='priceAsc'>Preço: Maior ao Menor</option>
                            <option value='A-Z'>A - Z</option>
                            <option value='Z-A'>Z - A</option>
                            <option value='newTime'>Mais novo ao mais antigo</option>
                            <option value='oldTime'>Mais antigo ao mais novo</option>
                            <option value='moreSelling'>Mais vendidos</option>
                        </select>
                    </div>
                </div>

                <div className="products_content">
                    <div className="products">
                        <div className='produtos_wrapper'>
                            {itemCategory.slice(0, initialPage).map((item, index) => {
                                const { nome, imagemPrincipal, valor, slug } = item;

                                const priceMore = (valor * 0.40);
                                const priceMorePercent = (valor + priceMore);
                                const StringPriceMorePercent = priceMorePercent?.toFixed(2).replace('.', ',');
                                const stringPrice = valor?.toFixed(2).replace('.', ',');

                                return (
                                    <div className='item_sigle' key={index} slug={slug}>
                                        <div className='item_img'>
                                            <Link to={`/produto/${slug}`}>
                                                <img src={imagemPrincipal?.url} title={nome} alt={nome} />
                                            </Link>
                                        </div>
                                        <Link to={`/produto/${slug}`}>
                                            <div className='item_descricao'>
                                                <h2>{nome}</h2>
                                                <div className='priceNoDescont'>
                                                    <div className='barraDecont'></div>
                                                    <p>DE <strong>R${StringPriceMorePercent}</strong></p>
                                                </div>
                                                <p>Por apenas</p>
                                                <strong>R${stringPrice}</strong>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}

                            <div className='ver_produtos'>
                                <BtnPrimry primary pad upper onClick={handleShowMore}>
                                    Ver mais produtos
                                </BtnPrimry>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </Container>
    );
};

export default Categorias;
