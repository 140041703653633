import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const ResetPass = () => {
    
    const { token } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({ password: '', confirmPassword: '' });
    const [loading, setLoading] = useState(false);

    const handleChangeValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmitReset = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (values.password !== values.confirmPassword) {
            alert('As senhas não coincidem!');
            return;
        }
        try {
            await api.post(`/mudar-senha/${token}`, {
                password: values.password,
                confirmPassword: values.confirmPassword
            });
            setLoading(false);
            alert('Senha redefinida com sucesso!');
            navigate('/login');
        } catch (error) {
            console.log(error);
            alert('Erro ao redefinir senha. Tente novamente.');
        }
    };

    return (
        <Container>
            <div className='nav_recover__title'>
                <p><Link to='/'>Início</Link> / <Link to='/login'>Login</Link> / Mudar minha senha</p>
                <h2>Mudar minha senha</h2>
            </div>
            <Content>
                <div className='form'>
                    <div className='msg_recover'>
                        <p>Escolha uma nova senha para a sua conta.</p>
                    </div>
                    <form onSubmit={handleSubmitReset}>
                        <p>Nova senha</p>
                        <input type='password' name='password' onChange={handleChangeValues} required />
                        <p>Repetir nova senha</p>
                        <input type='password' name='confirmPassword' onChange={handleChangeValues} required />
                        <div className='submit_form'>
                            <BtnSecondary 
                                secondary
                                upper 
                                pad 
                                disabled={loading} // Desativa o botão durante o loading
                                style={{ 
                                    backgroundColor: loading ? '#68C9A9' : '' // Define a cor de fundo "secondary" durante o loading
                                }}                    
                            >
                                {loading ? (
                                    <svg width="20" height="20" viewBox="0 0 300 150"><path fill="none" stroke="#FFFFFF" stroke-width="30" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>
                                ) : "Mudar senha"}
                        </BtnSecondary>
                        </div>
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default ResetPass;
