import { createGlobalStyle } from "styled-components";



const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Bebas Neue', cursive;
        font-family: 'Fredoka', sans-serif;
        font-family: 'Poppins', sans-serif;
        font-family: 'Roboto', sans-serif;
    }

    html, body, #root {
        height: 100%;
        overflow-x: hidden;
    }

    #root{
        display: flex;
        flex-direction: column;
    }

    input{
        outline: none;
    }

    .content{
        flex: 1;        
    }




`;


export default GlobalStyle;