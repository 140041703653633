import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .nav_info_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{
            
            font-size: 35px;
            color: #333;
            padding: 5px 0;
            letter-spacing: 3px;
            font-weight: lighter;
        }

        a{
            text-decoration: none;
        }
        
    }
`;

export const Content = styled.div`
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
    min-height: 300px;

    .form_editar{
        width: 600px;

        .input_style{
            margin-top: 15px;
            position: relative;
            p{
                color: #68C9A9;
                text-transform: uppercase;
                font-size: 11px;
                padding-bottom: 2px;
            }

            

            input{
                width: 100%;
                border: 1px solid #68C9A9;
                padding: 12px;

                ::placeholder{
                    color: #999;
                }
            }
        }

        .btn_editar{
            display: flex;
            justify-content: flex-end;
        }

        .msg_response{
            text-align: center;
            color: red;
            padding: 10px 0;
            font-weight: bold;
        }
    }

    @media screen and (max-width: 768px){
        .form_editar{
            width: 100%;
            padding: 0 10%;


            .btn_editar{
                display: flex;
                justify-content: center;
                
                button{
                    width: 100%;
                }
            }
        }
    }

`;