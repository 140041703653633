import styled from "styled-components";


export const Container = styled.div`

    padding: 100px 0;
    text-align: center;
    h2{
        color: #00bfa5;
        font-size: 60px;
    }

    p{
        font-size: 18px;
        color: #444;
        padding: 10px 0;
    }
`;