import React, { useContext } from 'react';
import { AuthProvider } from './contexts/authContext';
import { Context } from './contexts/authContext';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import Home from './pages/Home';
import Products from './pages/Produtos';
import Contato from './pages/Contato';
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import RecoverPass from './pages/RecoverPass';
import ResetPass from './pages/ResetPass';
import Perfil from './pages/Perfil';
import Info from './pages/Info';
import Item from './pages/Item';
import Header from './components/Header';
import Footer from './components/Footer';
import { ProductProvider } from './contexts/productsContext';
import Buscar from './pages/Buscar';
import Categorias from './pages/Categorias';
import Painel from './pages/Painel';
import Success from './pages/Success';
import Politic from './pages/Politic';

const AppRoutes = () => {

    const Private = ({ children }) => {
        const { authenticated, loading } = useContext(Context);

        if (loading) {
            return <h2>Carregando...</h2>
        }

        if (!authenticated) {
            return <Navigate to='/login' />;
        }

        return children;
    }

    const RolePrivate = ({ children }) => {
        const { authenticated, loading, user } = useContext(Context);

        if (loading) {
            return <h2>Carregando...</h2>
        }

        if (!authenticated || user.role !== process.env.REACT_APP_USER_ROLE) {
            return <Navigate to='/Perfil' />;
        }

        return children;
    }

    const RedirectIfAuthenticated = ({ children }) => {
        const { authenticated, loading } = useContext(Context);

        if (loading) {
            return <h2>Carregando...</h2>
        }

        if (authenticated) {
            return <Navigate to='/' />;
        }

        return children;
    }

    return (
        <ProductProvider>
            <AuthProvider>
                <Router>
                    <Header />
                    <Routes className="content">
                        <Route path='/' element={<Home />} />
                        <Route path='/produtos' element={<Products />} />
                        <Route path='/contato' element={<Contato />} />
                        <Route path='/login' element={<RedirectIfAuthenticated><Login /></RedirectIfAuthenticated>} />
                        <Route path='/criar-conta' element={<RedirectIfAuthenticated><Cadastro/></RedirectIfAuthenticated>} />
                        <Route path='/recuperar-conta' element={<RecoverPass />} />
                        <Route path='/Perfil' element={<Private><Perfil /></Private>} />
                        <Route path='/Perfil/Info' element={<Private><Info /></Private>} />
                        <Route path='/produto/:slug' element={<Item />} />
                        <Route path='/buscar' element={<Buscar />} />
                        <Route path='/produtos/:categoria' element={<Categorias />} />
                        <Route path='/Perfil/Painel' element={<RolePrivate><Painel /></RolePrivate>} />
                        <Route path='/Success' element={<Success />} />
                        <Route path='/mudar-senha/:token' element={<ResetPass />} />
                        <Route path='/Politic' element={<Politic />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </ProductProvider>
    );
}

export default AppRoutes;
