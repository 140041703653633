import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    height: auto;
    min-height: 100%;
    padding-bottom: 200px;

    .nav_item_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{
            
            font-size: 35px;
            color: #444;
            padding: 5px 0;
            letter-spacing: 3px;
            font-weight: normal;
        }

        a{
            text-decoration: none;
        }
        
    }

    @media screen and (max-width: 768px){

        padding: 0 10px;

        .nav_item_title{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        p{
            padding: 5px 0;
        }

        h3{           
            font-size: 18px;
            color: #444;
            padding: 15px 0;
            letter-spacing: 3px;
            font-weight: normal;
        }

        a{
            text-decoration: none;
        }
        
    }
    }
`;

export const Content = styled.div`
    max-width: 1400px;
    padding: 40px 2% 200px 2%;
    margin: 0 auto;

    .produto{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .carousel_images_desktop{
            .img_wrapper{
                display: flex;
                flex-direction: column;
                height: 448px;
                overflow: hidden;
                scroll-behavior: smooth;
                
                .item_img{
                    width: 200px;
                    min-height: 224px;
                    max-height: 224px;
                    padding: 0 10px 10px 0;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .arrow_up{
                margin-bottom: 3px;
                text-align: center;
                svg{
                    cursor: pointer;
                    background-color: rgba(240,230,230,0.8);
                    padding: 5px;
                    font-size: 30px;
                    border-radius: 4px;
                }
            }
            .arrow_down{
                margin-top: 5px;
                text-align: center;
                svg{
                    cursor: pointer;
                    background-color: rgba(240,230,230,0.8);
                    padding: 5px;
                    font-size: 30px;
                    border-radius: 4px;
                }
            }
        }

        

        .main_image{
            width: 540px;
            min-height: 500px; /* Altura mínima */
            display: flex;
            flex-direction: column;

            img.img_principal {
                width: 100%;
                height: auto;
                min-height: 700px;
                object-fit: cover;
                border: 1px solid #444;
            }

            .carousel_images_mobile{
                display: none;
                .img_wrapper{
                    display: flex;
                    overflow: hidden;
                    scroll-behavior: smooth;
                    
                    .item_img{
                        width: 180px;
                        height: 224px;
                        padding: 0 10px 10px 0;
                        transform: translate3d(0px, 0px, 0px);
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            cursor: pointer;
                            border: 1px solid #444;
                        }
                    }
                }

                .arrow_left{
                        margin: 0 10px;
                        text-align: center;
                        svg{
                            cursor: pointer;
                            background-color: rgba(240,230,230,0.8);
                            padding: 5px;
                            font-size: 30px;
                            border-radius: 4px;
                        }
                    }
                    .arrow_right{
                        margin: 0 10px;
                        text-align: center;
                        svg{
                            cursor: pointer;
                            background-color: rgba(240,230,230,0.8);
                            padding: 5px;
                            font-size: 30px;
                            border-radius: 4px;
                        }
                    }
            }

            .info_produto{
                color: #444;
                text-align: center;
                margin: 10px 0;
                h2{
                    font-size: 25px;
                    padding: 10px 0;
                }

                p{
                    text-transform: uppercase;
                    font-size: 14px;
                }


                p::after{
                    content: "";
                    width: 100%;
                    padding: 0.4px;
                    margin: 15px 0;
                    background-color: #ccc;        
                    display: block;
                }
            }

            .size-selection{

                .sizes{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    margin: 15px 0;
                    button{
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        cursor: pointer;
                        border: none;
                        background-color: #a7ffeb;
                        font-weight: bold;
                        &:hover{
                            background-color: #00bfa5;
                            color: #fff;
                        }
                        &:focus{
                            background-color: #00bfa5;
                            color: #fff;
                        }
                    }
                }
            }

            
        }

        .descricao{
            margin: 15px 0;
            h3{
                padding: 10px;
                text-transform: uppercase;
            }

            p{
                text-align: justify;
            }
            
        }

        .add-review{
            padding: 40px 0;
            h3{
                color: #444;
            }

            form{
                margin: 10px 0;

                textarea{
                    width: 100%;
                    height: 130px;
                    padding: 12px 8px;
                    border: 1px solid #68C9A9;
                    resize: vertical;
                    outline: none;
                }

                select{
                    background-color: #fff;
                    padding: 8px;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid #ccc;
                }

                select:focus{
                    border: 1px solid #00bfa5;
                }

                .select_btn{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
        }

        .reviews{
            h3{
                padding: 10px 0 30px 0;
                text-align: center;
                text-transform: uppercase;
                color: #444;
            }

            .review{
                background-color: rgba(240,250,250,0.8);
                padding: 8px;
                margin: 10px 0;
                border-radius: 5px;
                .user_details{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .comment_data{
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;


                    p{
                        color: #444;
                    }
                }
            }
        }


        @media screen and (max-width: 768px){

            .carousel_images_desktop{
                display: none;
            }

            .main_image{

                padding: 0 10px;

                img.img_principal {
                    width: 100%;
                    height: auto;
                    min-height: 380px;
                    object-fit: cover;
                    border: 1px solid #444;
                }


                .carousel_images_mobile{
                    display: flex;
                    align-items: center;
                    padding: 0;
                    
                    .img_wrapper{
                        display: flex;
                        overflow: hidden;
                        scroll-behavior: smooth;
                        margin-top: 10px;
                        
                        .item_img{
                            width: 50%;
                            max-width: 200px;
                            height: 150px;
                            padding: 0 6px 0 6px;
                            flex: none;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }

`;