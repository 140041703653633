import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 10px 2%;

    .nav_login__title{
        text-align: center;
        border-bottom: 1px solid rgba(44,62,80,0.05);

        h2{
            font-size: 40px;
            margin: 8px 0;
            letter-spacing: 2px;
            color: #333;
        }

        a{
            text-decoration: none;
        }
    }

`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
    min-height: 300px;

    .form_login{
        width: 600px;

        .input_style{
            position: relative;
            margin-top: 15px;
            p{
                color: #68C9A9;
                text-transform: uppercase;
                font-size: 11px;
                padding-bottom: 2px;
            }

            input{
                width: 100%;
                border: 1px solid #68C9A9;
                padding: 12px;
            }
        }

        .ver_senha{                               
                background-color: #f7f7f7;
                border: none;
                padding:  11.5px;
                text-decoration: none;
                color: #333;
                text-transform: uppercase;
                font-size: 14px;
                position: absolute;
                right: 1px;
                top: 16px;
                cursor: pointer;
                width: 88px;
                max-width: 33%;

                :hover{
                    background-color: #f1f1f1;
                }
            }

        .recuperar_conta{
            display: flex;
            justify-content: flex-end;

            a{
                padding: 8px 0;
                text-decoration: none;
                color: #68C9A9;
                font-size: 13px;
                :hover{
                    color: #00BFA5;
                    text-decoration: underline;
                }
            }
        }

        .btn_entrar{
            display: flex;
            justify-content: flex-end;
        }

        .error_message {
            color: red;
            margin-top: 10px;
            text-align: center;
            font-weight: bold;
        }


        .criar_conta{
            text-align: center;
            margin-top: 20px;
            font-size: 15px;

            a{
                color: #68C9A9;
                text-decoration: none;

                :hover{
                    color: #00BFA5;
                    text-decoration: underline;
                }
            }
        }
    }


    @media screen and (max-width: 768px){
        button{
            width: 100%;
        }
    }
`;