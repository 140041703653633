import React, { createContext, useEffect, useState } from "react";
import { api, useApi } from "../hooks/useApi";

const Context = createContext();

const AuthProvider = ({ children }) => {
    const auth = useApi();
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState([]);
    const [permissions, setPermissions] = useState();

    useEffect(() => {
        const recoverUser = localStorage.getItem('u');
        const token = localStorage.getItem('token');
        if (recoverUser && token) {
            setUser(JSON.parse(recoverUser));
            api.defaults.headers.Authorization = `Bearer ${token}`;
        }
        setLoading(false);
    }, []);

    const updateUserInLocalStorage = (updatedUser) => {
        localStorage.setItem('u', JSON.stringify(updatedUser));
        setUser(updatedUser);
    };
    

    const signin = async (email, password) => {
        const data = await auth.signin(email, password);
        if (data.user && data.token) {
            setUser(data.user);
            localStorage.setItem('token', data.token);
            localStorage.setItem('u', JSON.stringify(data.user));
            api.defaults.headers.Authorization = `Bearer ${data.token}`;
            setAuthenticated(true);
            setLoading(false);
            return true;
        }
    };

    const signout = async () => {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('u');
        setLoading(false);
    };

    const editPerfil = async (nome, email, telefone) => {
        const data = await auth.editUser(nome, email, telefone).catch(error => {
            setMsg(error.response.data.msg);
            setInterval(() => {
                setMsg([]);
            }, 4000);
        });
    
        const updatedUser = {
            ...user,
            nome: nome || user.nome,
            email: email || user.email,
            telefone: telefone || user.telefone,
            pedidos: user.pedidos  // Preserva os pedidos
        };
    
        localStorage.setItem('u', JSON.stringify(updatedUser));
        setUser(updatedUser);
        return true;
    };
    

    return (
        <Context.Provider value={{ authenticated: !!user, user, setUser, signin, signout, editPerfil, loading, msg, permissions }}>
            {children}
        </Context.Provider>
    );
};

export { Context, AuthProvider };
