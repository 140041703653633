import React, { useState } from 'react';
import { Container, Content } from './style';
import { Link } from 'react-router-dom';
import { BtnSecondary } from '../../styles/Buttons';
import { api } from '../../hooks/useApi';

const RecoverPass = () => {
    const [values, setValues] = useState({ email: '' });
    const [loading, setLoading] = useState(false);

    const handleChangeValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubmitRecover = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await api.post('/recuperar-senha', { email: values.email });
            setLoading(false);
            alert('Verifique o e-mail informado para redefinir sua senha!');
        } catch (error) {
            console.log(error);
            alert('Erro ao enviar e-mail de recuperação de senha. Tente novamente.');
        }
    };

    return (
        <Container>
            <div className='nav_recover__title'>
                <p><Link to='/'>Início</Link> / <Link to='/login'>Login</Link> / Recuperar minha senha</p>
                <h2>Recuperar minha senha</h2>
            </div>
            <Content>
                <div className='form'>
                    <div className='msg_recover'>
                        <p>Vamos te enviar um e-mail para poder alterar a sua senha.</p>
                    </div>
                    <form onSubmit={handleSubmitRecover}>
                        <p>Email</p>
                        <input type='email' name='email' onChange={handleChangeValues} required />
                        <div className='submit_form'>
                        <BtnSecondary 
                        secondary
                        upper 
                        pad 
                        disabled={loading} // Desativa o botão durante o loading
                        style={{ 
                            backgroundColor: loading ? '#68C9A9' : '' // Define a cor de fundo "secondary" durante o loading
                        }}                    
                    >
                        {loading ? (
                            <svg width="20" height="20" viewBox="0 0 300 150"><path fill="none" stroke="#FFFFFF" stroke-width="30" stroke-linecap="round" stroke-dasharray="300 385" stroke-dashoffset="0" d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"><animate attributeName="stroke-dashoffset" calcMode="spline" dur="2" values="685;-685" keySplines="0 0 1 1" repeatCount="indefinite"></animate></path></svg>
                        ) : "Enviar Email"}
                    </BtnSecondary>
                        </div>
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default RecoverPass;
