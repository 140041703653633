import axios from "axios";
const API_URL = 'https://graphql.datocms.com/';
const API_TOKEN = process.env.REACT_APP_API_TOKEN;


export const datocms = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${API_TOKEN}`,
    },
});